<template>
  <div id="logs">
    <template v-if="list">
      <el-table
        v-loading="loading"
        :header-cell-style="getRowClass"
        :data="list"
        border
        style="width: 100%"
      >
        <!-- 显示产品 购买人  状态  订单号 快递公司 ~ 快递单号 【查看物流】 -->
        <!-- <el-table-column prop="time" label="下单时间" width="150"> </el-table-column> -->

        <el-table-column
          prop="title"
          :show-overflow-tooltip="true"
          label="产品标题"
        >
        </el-table-column>

        <el-table-column prop="zhuangtai" label="客户信息" width="200">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="详细信息"
              width="200"
              trigger="hover"
              :content="scope.row.info"
            >
              <span class="info" slot="reference">{{ scope.row.info }}</span>
            </el-popover>

            <!-- <span>地址:{{scope.row.address_info.province_name+scope.row.address_info.city_name+scope.row.address_info.county_name+scope.row.address_info.detail_info}}</span> -->
          </template>
        </el-table-column>

        <el-table-column prop="zhuangtai" label="订单状态" width="90">
        </el-table-column>

        <el-table-column prop="order_id" label="订单号" width="150">
        </el-table-column>

        <el-table-column prop="delivery_name" label="快递公司" width="90">
        </el-table-column>

        <el-table-column prop="zhuangtai" label="操作" width="120">
          <template slot-scope="scope" >
            <el-button
              v-if='scope.row.from=="本地仓"'
              size="small"
            
              @click="order(scope.row.order_id)"
              type="primary"
              >查看详情</el-button
            >

            <template v-else>
               <el-button v-if='scope.row.zhuangtai=="已发货"'
                  size="small"
                  @click="wuliu(scope.row.toskuid)"
                  type="primary"
                  >查看物流</el-button
                >

                 <el-button
               v-else
              size="small"
            
              @click="order(scope.row.order_id)"
              type="primary"
              >查看详情</el-button
            >
                

            </template>
         
          </template>

          
        </el-table-column>
      </el-table>

      <div class="page">
        <el-pagination
          background
          v-loading="loading"
          layout="prev, pager, next"
          :total="total"
          :current-page="page"
          :page-size="20"
          @current-change="change"
        >
        </el-pagination>
      </div>
      <el-drawer
        title="物流信息"
        :visible.sync="drawer"
        size="40%"
        :direction="direction"
        :before-close="handleClose"
      >
        <div class="wuliu">
          <el-timeline :reverse="reverse">
            <el-timeline-item
              v-for="(activity, index) in wlData"
              :key="index"
              :timestamp="activity.timestamp"
            >
              {{ activity.message }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-drawer>
    </template>
  </div>
</template>
<style lang="scss">
.wuliu {
  width: 100%;
  height: 80%;
  box-sizing: border-box;
  padding: 30px;
  overflow-y: scroll;
}
.info{

overflow:hidden; //超出的文本隐藏
text-overflow:ellipsis; //溢出用省略号显示
white-space:nowrap; //溢出不换行
}
</style>
<script>
export default {
  name: "MyOrder",
  data() {
    return {
      list: [],
      loading: true,
      page: 1,
      total: 0,
      drawer: false,
      direction: "rtl",
      wlData: [],
      reverse: true
    };
  },
  methods: {
    // 列表
    getList() {
      this.loading = true;
      this.axios
        .get(this.api.myorder, {
          params: {
            page: this.page
          }
        })
        .then(res => {
          this.loading = false;
          this.total = Number(res.count);
          if (res.data != "") {
            res.data.forEach(element => {
              element.time = this.getDate(element.addtime);
              // 姓名:{{scope.row.address_info.user_name}}
              element.info =
                "姓名：" +
                element.address_info.user_name +
                "  手机号：" +
                element.address_info.tel_number +
                "  地址：" +
                element.address_info.province_name +
                element.address_info.city_name +
                element.address_info.county_name +
                element.address_info.detail_info;
            });
            this.list = [...this.list, ...res.data];
          }
        });
    },
    //分页
    change(val) {
      this.page = val;
      this.getList();
    },
    order(id){
      this.$router.push({
          path: `/orderdetail/${id}`,
      })
    },
    // 转化年月日
    getDate(time) {
      var date = new Date(Number(time * 1000));
      let Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = date.getDate() + " ",
        h = date.getHours() + ":",
        m = date.getMinutes();
      // s = date.getSeconds();
      return Y + M + D + h + m;
    },
    //列表样式
    getRowClass({ row, column, rowIndex }) {
      console.log(row, column);
      if (rowIndex === 0) {
        return "background:#F5F7FA;color:#353535;";
      } else {
        return "";
      }
    },

    wuliu(id) {
     
      this.axios
        .get(this.api.wuliu, {
          params: {
            sku: id
          }
        })
        .then(res => {
          res.data.list.forEach(item => {
            item.timestamp = this.getDate(Number(item.time));
          });
          this.wlData = res.data.list;
          this.drawer = true;
        });
      // wuliu
    },
    handleClose(done) {
      this.wlData = [];
      done();
    }
  },
  created() {
    this.getList();
  }
};
</script>